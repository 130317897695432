<template>
  <div class="helpstandard">
      <div class="standard-container">
          <h2>资助标准</h2>
             <div class="standard-content">
                 <ul>
                     <li v-for="(item,index) in fund_standard" :key="index">{{item}}</li>
                     <!-- <li>1、对完成造血干细胞移植手术的患儿每人一次性资助<span>5</span>万元</li>
                     <li>2、对无需造血干细胞移植手术或需要移植但尚未实施移植手术的白血病患儿每人一次性资助<span>3</span>万元</li>
                     <li>3、患儿在获得3万元资助款后完成造血干细胞移植手术，补充一次性资助<span>2</span>万元</li> -->
                 </ul>
             </div>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    fund_standard: {
        type: Array,
        default:() => []
    },
 
  },
  name: 'Helpstandard'
}
</script>

<style lang="less" scoped>
.helpstandard {
    .standard-container {
        width: 781px;
        min-height: 322px;
        // border: 2px dashed #FFFFFF;
        margin: 0 auto;
        margin-top: 115px;
        margin-left: 100px;
        border-radius: 24px;
         h2{
            font-size: 24px;
            color: #FFFFFF;
            margin-bottom: 6px;
            margin-left: 29px;
            }
        .standard-content {
            width: 781px;
            min-height: 222px;  
            border: 2px dashed #FFFFFF;            
            border-radius: 24px;
            ul {
                width: 726px;
                height: 89px;
                // border: 1px solid #666666;
                position: relative;
                margin: 15px 26px 18px 29px;
                li {
                    line-height: 24px;
                    color: #FFFFFF;
                    span {
                        color: #FFF607;
                        font-size: 30px;
                        padding: 0 10px;
                    }
                }
                &::after {
                    content: "";
                    display: block;
                    width: 274px;
                    height: 170px;
                    top: -2px;
                    right: -270px;
                    position: absolute;
                    background: url('../../../../assets/img/children.png') no-repeat center center;
                }
            }
        }
    }
}
</style>
